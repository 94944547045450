import React, {useEffect, useRef, useState} from 'react';
import {Chip, Typography} from 'spenda-ui-react';
import {AlertDialogSlideV2} from '../dialog/AlertDialogSlideV2';
import {camelCase, upperCase} from 'lodash';
import Skeleton from 'react-loading-skeleton';
import {Inventory, VariationOptions} from '../../model/inventory/Inventory';
import {ImageIcon} from '../../assets/svg';
import { IAddProductVariationOptions } from '../../model/variants';

interface ISelectVariantDialog {
  handleCancel: () => void;
  handleAdd: (selectedVariant: {
    ID: number;
    ShortDescription: string;
    SKU: string;
    StandardSellPriceInc: number;
    CostPriceEx: number;
  }) => Promise<void>;
  item?: Inventory;
  isLoading?: boolean;
}

interface OptionComponentProps {
  option: VariationOptions;
  variationIndex: number;
  optionIndex: number;
  selectedVariation: number | null;
  handleSelectVariation: (variationIndex: number, optionIndex: number) => void;
  isOptionValid: (variationIndex: number, optionIndex: number) => boolean;
  isLoading?: boolean;
  isVariationData?: boolean;
}

const OptionComponent: React.FC<OptionComponentProps> = ({
  option,
  variationIndex,
  optionIndex,
  selectedVariation,
  handleSelectVariation,
  isOptionValid,
  isLoading,
  isVariationData,
}) => {
  const isValid = isOptionValid(variationIndex, optionIndex);
  return (
    <div className="flex flex-col items-center" key={option?.VariationOptionID}>
      {isLoading ? (
        <Skeleton width={'4rem'} height={'4rem'} />
      ) : (
        <>
          {isVariationData ? (
            <>
              <div
                className={`flex h-[108px] max-w-[108px] cursor-pointer items-center justify-center gap-2 rounded-md bg-spenda-cream p-4
                  ${selectedVariation === optionIndex ? 'border border-primary' : 'border-[#ECECEC] bg-spenda-cream'}
                  ${!isValid ? 'pointer-events-none opacity-50' : ''}`}
                onClick={() => isValid && handleSelectVariation(variationIndex, optionIndex)}
              >
                {option.MediaFileUrl ? (
                  <img
                    src={option.MediaFileUrl}
                    alt="Image of product"
                    className="w-30 h-20 rounded-md"
                    data-autoid={`imgProduct`}
                  />
                ) : option.Colour ? (
                  <div
                    className="shadow-xs min-h-[88px] min-w-[88px] rounded-md border border-[#ECECEC]"
                    style={{backgroundColor: `#${option.Colour}`, minWidth: '88px', minHeight: '88px'}}
                  ></div>
                ) : (
                  <div
                    className="shadow-xs flex min-h-[88px] min-w-[88px] items-center justify-center rounded-md border border-[#ECECEC]"
                    style={{backgroundColor: `#${option.Colour}`, minWidth: '88px', minHeight: '88px'}}
                  >
                    <ImageIcon />
                  </div>
                )}
              </div>
              <div className="flex flex-col">
                <Typography
                  className={`mt-2 text-center font-medium ${!isValid ? 'text-spenda-labeltext' : 'text-spenda-primarytext'}`}
                  data-autoid={`txt${upperCase(camelCase(option?.Name))}`}
                >
                  {option?.Name}
                </Typography>

                {/* The out of stock label is if SOH (stock on hand) is <=0. 
                Hiding this UI label until we implement stock on hand tracking */}

                {/* <Typography variant="paragraph" className={`text-center text-spenda-error`}>
                Out of stock
              </Typography> */}
                {option.StandardSellPriceExDelta !== null && option.StandardSellPriceExDelta !== 0 && (
                  <Typography
                    variant="small"
                    className={`font-medium text-spenda-sGreen  ${!isValid ? ' opacity-50' : ''}`}
                  >
                    {`$${option?.StandardSellPriceExDelta?.toFixed(2)} (ex) `}
                  </Typography>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col items-center gap-2">
                <div
                  className={`flex h-[32px] max-w-[148px] cursor-pointer items-center justify-center overflow-hidden text-ellipsis whitespace-nowrap rounded-md border p-5 px-6
                    ${selectedVariation === optionIndex ? 'border-primary' : 'border-[#ECECEC] bg-spenda-cream'}
                    ${!isValid ? 'pointer-events-none opacity-50' : ''}`}
                  onClick={() => isValid && handleSelectVariation(variationIndex, optionIndex)}
                >
                  <Typography
                    className="text-center font-medium text-spenda-primarytext"
                    data-autoid={`txt${upperCase(camelCase(option.Name))}`}
                  >
                    {option.Name}
                  </Typography>
                </div>
                { option.StandardSellPriceExDelta !== null && option.StandardSellPriceExDelta !== 0 && (
                  <Typography
                    variant="small"
                    className={`font-medium text-spenda-sGreen  ${!isValid ? ' opacity-50' : ''}`}
                  >
                    {`$${option?.StandardSellPriceExDelta?.toFixed(2)} (ex) `}
                  </Typography>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const DividerWithText: React.FC<{text: string}> = ({text}) => (
  <div className="relative flex w-full items-center justify-center" data-autoid={`divider-${text}`}>
    <div className="absolute bg-white px-2 font-medium text-spenda-labeltext">{text}</div>
    <div className="h-[1px] w-full border border-[#ECECEC]" />
  </div>
);

export const SelectVariantDialog: React.FC<ISelectVariantDialog> = ({handleCancel, handleAdd, item, isLoading}) => {
  const [selectedOptions, setSelectedOptions] = useState<(number | null)[]>(item?.Variations?.map(() => null) || []);
  const containerRef = useRef<HTMLDivElement>(null);
  const rowRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleSelectVariation = (variationIndex: number, optionIndex: number) => {
    setSelectedOptions(prevSelectedOptions => {
      const newSelectedOptions = [...prevSelectedOptions];
      newSelectedOptions[variationIndex] = newSelectedOptions[variationIndex] === optionIndex ? null : optionIndex;
      for (let i = variationIndex + 1; i < newSelectedOptions.length; i++) {
        newSelectedOptions[i] = null;
      }
      return newSelectedOptions;
    });
  };

  const isOptionValid = (variationIndex: number, optionIndex: number) => {
    // Checking if a given option in the first variation row has any child variants linked to it
    if (variationIndex === 0) {
      const hasChildVariants = item?.ChildVariants?.some(variant =>
        variant.VariationOptions?.some(
          option =>
            option.VariationOptionID ===
              item?.Variations?.[variationIndex]?.Options?.[optionIndex]?.VariationOptionID && variant.ID !== null,
        ),
      );
      return !!hasChildVariants;
    }
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[variationIndex] = optionIndex;

    const variant = item?.ChildVariants?.find((variant: any) =>
      variant.VariationOptions.every((option: any, index: number) => {
        if (index > variationIndex) return true;

        const selectedOptionIndex = newSelectedOptions[index];
        const selectedOption =
          (selectedOptionIndex !== null && item?.Variations?.[index]?.Options?.[selectedOptionIndex]) || null;

        return selectedOption?.VariationOptionID === option.VariationOptionID;
      }),
    );

    return variant?.ID !== null;
  };

  useEffect(() => {
    const nextRowIndex = selectedOptions.findIndex(option => option === null);
    if (nextRowIndex !== -1 && rowRefs.current[nextRowIndex]) {
      rowRefs.current[nextRowIndex]?.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  }, [selectedOptions]);

  const getSelectedVariant = () => {
    if (!item?.ChildVariants) {
      return null;
    }
    const variant = item.ChildVariants.find((variant: any) =>
      variant.VariationOptions.every((option: any, index: number) => {
        const selectedOptionIndex = selectedOptions[index];
        const selectedOption =
          (selectedOptionIndex !== null && item?.Variations?.[index]?.Options?.[selectedOptionIndex]) || null;
        return selectedOption?.VariationOptionID === option.VariationOptionID;
      }),
    );
    return variant;
  };

  const getPriceRange = (item: any) => {
    if (item?.StandardSellPriceInc !== undefined && item?.MaxStandardSellPriceInc !== undefined) {
      return `$${item.StandardSellPriceInc.toFixed(2)} to $${item.MaxStandardSellPriceInc.toFixed(2)}`;
    }
    return 'Price not available';
  };

  const handleAddClick = () => {
    const selectedVariant = getSelectedVariant();
    if (selectedVariant && selectedVariant.ID) {
      handleAdd({
        ID: selectedVariant.ID,
        ShortDescription: selectedVariant.ShortDescription!,
        SKU: selectedVariant.SKU!,
        StandardSellPriceInc: selectedVariant.StandardSellPriceInc!,
        CostPriceEx: selectedVariant.CostPriceEx!,
      });
    }
  };

  const hasChildVariants = item?.ChildVariants && item?.ChildVariants?.length > 0;

  return (
    <AlertDialogSlideV2
      headingClassess="border-b border-[#EAEAEA] flex items-center justify-center"
      dialogActionsAlignment="justify-between"
      dialogBodyClassess="flex h-[700px] flex-col gap-3 overflow-y-scroll mb-2"
      dialogClassess="!min-w-[948px] h-[840px]"
      data-autoid="dlgSelectVariant"
      actions={[
        {
          label: 'Cancel',
          variant: 'outlined',
          action: handleCancel,
        },
        {
          label: 'Add',
          action: handleAddClick,
          disabled: !hasChildVariants || selectedOptions.some(option => option === null),
        },
      ]}
      title="Select variant"
    >
      <div ref={containerRef} className="mx-10 my-4 mb-20 flex min-h-[100px] flex-col gap-12">
        <div className="!mt-4 flex items-center justify-between">
          <div className="flex items-center gap-4">
            {item?.MediaFiles?.[0]?.URL &&
              (isLoading ? (
                <Skeleton width={80} height={80} />
              ) : (
                <img
                  src={item?.MediaFiles[0].URL}
                  alt="Product Image"
                  className="h-20 w-20"
                  data-autoid="imgProductImage"
                />
              ))}
            <div className="flex flex-col gap-2">
              {item?.Categories && item.Categories.length > 0 && (
                <Chip
                  value={item.Categories[0]?.Name}
                  color="gray"
                  className="flex h-fit max-w-[100px] justify-center p-2 leading-none text-spenda-primarytext"
                  data-autoid="chipCategory"
                />
              )}
              <div className="flex gap-3">
                <Typography variant="h3" className="text-black" data-autoid="txtProductName">
                  {item?.ShortDescription}
                </Typography>
              </div>
              <Typography variant="paragraph" className="text-spenda-labeltext" data-autoid="txtProductMasterCode">
                {item?.InventoryCode}
              </Typography>
            </div>
          </div>
          {selectedOptions.every(option => option !== null) && hasChildVariants ? (
            <div className="flex flex-col items-end justify-end gap-1">
              <Typography variant="paragraph" className="text-spenda-labeltext" data-autoid="txtProductPrice">
                Price
              </Typography>
              <Typography variant="h3" className="text-black" data-autoid="txtProductPrice">
                {getSelectedVariant()?.StandardSellPriceInc &&
                  `$${getSelectedVariant()?.StandardSellPriceInc?.toFixed(2)}`}
              </Typography>
            </div>
          ) : (
            <div className="flex flex-col items-end justify-end gap-1">
              <Typography variant="paragraph" className="text-spenda-labeltext" data-autoid="txtProductPrice">
                From
              </Typography>
              <Typography variant="h3" className="text-black" data-autoid="txtProductMinMaxPrice">
                {getPriceRange(item)}
              </Typography>
            </div>
          )}
        </div>

        {item?.Variations && item.Variations.length > 0 && hasChildVariants ? (
          item?.Variations.map(
            (variation: any, variationIndex: number) =>
              variation.Options &&
              (variationIndex === 0 || selectedOptions[variationIndex - 1] !== null) && (
                <React.Fragment key={variation.VariationID}>
                  <DividerWithText text={`Choose your ${variation.Name.toLowerCase()}`} />
                  <div ref={el => (rowRefs.current[variationIndex] = el)} className="flex flex-wrap gap-4">
                    {variation.Options.map((option: any, optionIndex: number) => {
                      const hasVariationData = variation.Options.some((opt: IAddProductVariationOptions) => opt.Colour || opt.MediaFileUrl);
                      return (
                        <OptionComponent
                          key={option.VariationOptionID}
                          option={option}
                          variationIndex={variationIndex}
                          optionIndex={optionIndex}
                          selectedVariation={selectedOptions[variationIndex]}
                          handleSelectVariation={handleSelectVariation}
                          isLoading={isLoading}
                          isOptionValid={isOptionValid}
                          isVariationData={hasVariationData}
                        />
                      );
                    })}
                  </div>
                </React.Fragment>
              ),
          )
        ) : (
          <Typography variant="paragraph" className="mx-20 my-auto text-center text-spenda-labeltext">
            You don't seem to have any linked inventory items for this product! To link an inventory item, just dismiss
            this window and edit this inventory item.
          </Typography>
        )}
      </div>
    </AlertDialogSlideV2>
  );
};
