import React, {FunctionComponent, useState, useRef, useEffect, useCallback} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import {camelCase, debounce, omit} from 'lodash';
import moment, {Moment} from 'moment';
import {DateRangePicker, FocusedInputShape} from 'react-dates';
import CloseIcon from '@material-ui/icons/Close';
import {Button, Checkbox, Menu, MenuHandler, MenuList, SearchInput, IconButton as SIconButton} from 'spenda-ui-react';
import {Box, Collapse, Grow, IconButton, Popper, makeStyles} from '@material-ui/core';
import {usePopupState, bindToggle, bindPopper} from 'material-ui-popup-state/hooks';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import {CONSIGNMENT_NOTE_STATUS, PICK_SLIP_STATUS, SalesOrdersViewMode} from '../../model/constants/Constants';
import {AUTH_SELLING_SALES_ORDER_CREATE} from '../../routes/SalesOrderRoutes';
import {ISearchFilter} from '../../model/search-filters/SearchFilter';
import {STableToolbar} from '../tables/STableToolbar';
import {SSearchInput} from '../inputs/SSearchInput';
import IconFilter from '../../assets/svg/IconFilter';
import {VisibleContent} from '../ui/VisibleContent';
import {CloseCross} from '../../assets/svg/CloseCross';

export interface ISalesOrderToolbar {
  showCreateOrder?: boolean;
  title?: string | React.ReactNode;
  loading?: boolean;
  searchFilter: ISearchFilter;
  columns?: any;
  selectedColumns?: any;
  setSelectedColumns?: any;
  selected?: number[];
  data?: any[];
  refetchList?: () => void;
  setSelected?: (selected: number[]) => void;
  setSearchFilter: (filter: any) => void;
}

export const useInventoryToolbarStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
    fontSize: '1.25em',
    color: '#4D4D4D',
    lineHeight: 1.1,
  },
  filterContainer: {
    display: 'flex',
    backgroundColor: '#FAFAFA',
    border: '1px solid #ECECEC',
    borderRadius: '8px',
    justifyContent: 'flex-end',
    width: '100%',
    margin: '1rem 0',
    padding: '0.5rem 0',
    minHeight: '66px',
  },
  dateRangeStyles: {
    '& .DateRangePickerInput ': {
      height: '40px',
      '& .DateInput_input': {
        marginBottom: '0px',
      },
      '& #your_unique_start_date_id': {
        paddingLeft: '6px',
      },
      '& .DateRangePickerInput_arrow': {
        margin: '0px 26px 0px 10px',
      },
    },
    '& .DateInput_input__focused': {
      borderBottom: '2px solid hsl(var(--primary))',
    },
    '& .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover': {
      background: 'hsl(var(--primary))',
      border: '1px double hsl(var(--primary))',
    },
    '& .CalendarDay__hovered_span:active, .CalendarDay__hovered_span, .CalendarDay__selected_span, .CalendarDay__hovered_span:hover ':
      {
        background: 'hsl(var(--primary) / 0.2)',
        border: '1px double hsl(var(--primary))',
        color: 'hsl(var(--primary))',
      },
    '& .DayPickerKeyboardShortcuts_show__bottomRight::before': {
      borderRight: '33px solid hsl(var(--primary))',
    },
    '& .DayPickerNavigation_button__default': {
      border: '1px solid #eaeff2',
      backgroundColor: '#eaeff2',
      '& .DayPickerNavigation_svg__horizontal': {
        fill: 'hsl(var(--primary))',
      },
    },
  },
  closeButton: {
    color: theme.palette.grey[500],
    marginLeft: '1rem',
  },
  '&.CalendarDay__selected_span': {
    background: 'red !important',
    color: 'red !important',
    border: '1px solid red !important',
  },
  iconButton: {
    color: 'hsl(var(--primary))',
    border: '1px solid hsl(var(--primary))',
    borderRadius: '6px',
    padding: '5px',
    margin: '0 0.25rem',
    width: 40,
    height: 40,
  },
  iconButtonActive: {
    borderRadius: '0 6px 6px 0',
    backgroundColor: 'hsl(var(--primary))',
    '& path': {
      fill: '#FFFFFF',
    },
    '&:hover': {
      borderRadius: '0 6px 6px 0',
      backgroundColor: 'hsl(var(--primary))',
    },
  },
  textfield: {
    fontFamily: 'Poppins',
    '& .MuiOutlinedInput-root': {
      border: '1px solid hsl(var(--primary))',
      borderRadius: '6px 0 0 6px',
    },
    '& .MuiInputBase-root': {
      color: '#333333',
      padding: '0 5px',
      height: '100%',
      '&::placeholder': {
        color: '#BBBBBB',
        fontWeight: 600,
      },
    },
  },
  filterButtonActive: {
    borderRadius: '6px 0 0 6px',
    borderRight: 'none',
  },
  filterButtonContainer: {
    '& .DateRangePickerInput': {
      border: '1px solid hsl(var(--primary))',
      borderLeft: 'none',
      borderRadius: '0 6px 6px 0',
      overflow: 'hidden',
      '& .DateInput': {
        '& .DateInput_input': {
          marginBottom: '0px',
          padding: '6px',
          fontSize: '14px',
          fontWeight: 600,
          color: '#BBBBBB',
          textAlign: 'center',
        },
      },
      '& .DateRangePickerInput_arrow': {
        fontSize: '14px',
        fontWeight: 600,
        color: 'hsl(var(--primary))',
        margin: 'auto 10px',
      },
    },
  },
}));

export const SalesOrderToolbar: FunctionComponent<Partial<ISalesOrderToolbar>> = props => {
  const classes = useInventoryToolbarStyles();
  const searchInputRef = useRef<HTMLDivElement>(null);

  const [startDate, setStartDate] = useState<Moment | null>(moment());
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');

  const {title, loading, columns, searchFilter, selectedColumns, setSearchFilter, setSelectedColumns} = props;

  const dateFilterPopup = usePopupState({variant: 'popover', popupId: 'dateFilterPopup'});

  useEffect(() => {
    setEndDate(null);
    setStartDate(null);
  }, [searchFilter?.ViewMode]);

  useEffect(() => {
    if (!dateFilterPopup.isOpen) {
      setFocusedInput(null);
    }
  }, [dateFilterPopup.isOpen]);

  const debouncedSearch = useCallback(
    debounce((newValue: string) => {
      if (setSearchFilter) {
        setSearchFilter({
          ...omit(searchFilter, ['StartRow', 'Status']),
          StartRow: 1,
          SearchString: newValue,
          ...((searchFilter?.ViewMode === SalesOrdersViewMode.ReadyToPack ||
            searchFilter?.ViewMode === SalesOrdersViewMode.AwaitingCollection) &&
          newValue
            ? {Status: Object.values(CONSIGNMENT_NOTE_STATUS)}
            : {}),
          ...(searchFilter?.ViewMode === SalesOrdersViewMode.ReadyToPick && newValue
            ? {Status: Object.values(PICK_SLIP_STATUS)}
            : {}),
        });
      }
    }, 500),
    [searchFilter],
  );

  const toggleFilter = () => {
    setShowFilter(!showFilter);
    setEndDate(null);
    setStartDate(null);
    if (showFilter && setSearchFilter) {
      setSearchFilter({...omit(searchFilter, ['StartDate', 'EndDate'])});
    }
  };

  const handleColumnCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSelected = [...selectedColumns];
    if (event.target.checked) {
      currentSelected.push(event.target.name);
    } else {
      const index = currentSelected.indexOf(event.target.name);
      if (index !== -1) {
        currentSelected.splice(index, 1);
      }
    }
    setSelectedColumns(currentSelected);
  };

  const handleSearchInput = (newValue: string) => {
    if (setSearchFilter) {
      setSearchFilter({
        ...omit(searchFilter, ['StartRow', 'Status']),
        StartRow: 1,
        SearchString: newValue,
        ...((searchFilter?.ViewMode === SalesOrdersViewMode.ReadyToPack ||
          searchFilter?.ViewMode === SalesOrdersViewMode.AwaitingCollection) &&
        newValue
          ? {Status: Object.values(CONSIGNMENT_NOTE_STATUS)}
          : {}),
        ...(searchFilter?.ViewMode === SalesOrdersViewMode.ReadyToPick && newValue
          ? {Status: Object.values(PICK_SLIP_STATUS)}
          : {}),
      });
    }
  };

  const handlendDatesChange = (arg: {startDate: Moment | null; endDate: Moment | null}) => {
    setStartDate(arg.startDate);
    setEndDate(arg.endDate);
    setFocusedInput(null);
    if (setSearchFilter && arg.startDate && arg.endDate) {
      setSearchFilter({
        ...omit(searchFilter, ['StartRow']),
        StartRow: 1,
        StartDate: moment(arg.startDate).format('YYYY/MM/DD'),
        EndDate: moment(arg.endDate).format('YYYY/MM/DD'),
      });
    }
  };

  const handleFocusChange = (arg: FocusedInputShape | null) => {
    if (arg) {
      setFocusedInput(arg);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleClearFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setFocusedInput(null);
    setSearchFilter?.({
      ...omit(searchFilter, ['StartRow', 'StartDate', 'EndDate']),
      StartRow: 1,
    });
  };

  return (
    <STableToolbar>
      <div className="flex w-full items-center justify-between pt-2">
        <p className={`${classes.title}`}>{title}</p>
        <div className="flex">
          <div className="w-52">
            <SearchInput
              data-autoid="txtSearch"
              autoFocus
              placeholder="Search..."
              value={searchInput}
              reverse
              onChange={handleSearchChange}
              clearIcon
              clearIconProps={{
                onClick: () =>
                  handleSearchChange({
                    target: {
                      value: '',
                    },
                  } as React.ChangeEvent<HTMLInputElement>),
              }}
            />
          </div>

          {
            <div className={classes.filterButtonContainer}>
              <IconButton
                disableRipple
                className={clsx(classes.iconButton, {
                  [classes.filterButtonActive]: dateFilterPopup.isOpen,
                  '!mr-[292px]': dateFilterPopup.isOpen,
                })}
                data-autoid="btnSearchFilter"
                {...bindToggle(dateFilterPopup)}
              >
                <IconFilter />
              </IconButton>
              <Popper
                {...bindPopper(dateFilterPopup)}
                disablePortal={true}
                placement="right"
                transition
                style={{height: '40px'}}
              >
                {({TransitionProps}) => (
                  <Grow {...TransitionProps} timeout={250}>
                    <div className={classes.dateRangeStyles + ' relative'}>
                      <DateRangePicker
                        disableScroll
                        hideKeyboardShortcutsPanel
                        isOutsideRange={day => day.isAfter(moment(), 'day')}
                        startDatePlaceholderText={'DD/MM/YYYY'}
                        endDatePlaceholderText={'DD/MM/YYYY'}
                        displayFormat={'DD/MM/YYYY'}
                        startDate={startDate}
                        startDateId="your_unique_start_date_id"
                        endDate={endDate}
                        endDateId="your_unique_end_date_id"
                        focusedInput={focusedInput}
                        onFocusChange={handleFocusChange}
                        onDatesChange={handlendDatesChange}
                        customArrowIcon={'-'}
                        minimumNights={0}
                      />
                    </div>
                  </Grow>
                )}
              </Popper>
            </div>
          }
          {startDate && endDate && (
            <SIconButton onClick={handleClearFilter} name="clearFilter" className="ml-0.5 mr-1" variant="outlined">
              <CloseCross name="clear" />
            </SIconButton>
          )}
          <Menu
            placement="bottom-end"
            dismiss={{
              itemPress: false,
            }}
          >
            <MenuHandler>
              <Button variant="outlined" className="mr-1 focus:ring-0">
                Columns Settings
              </Button>
            </MenuHandler>
            <MenuList className="flex flex-col border border-primary p-0">
              {Object.entries(columns)
                .filter(([, value]: [string, any]) => !value.hide)
                .map(([key, value]: [string, any]) => {
                  return (
                    <div
                      key={key}
                      className="flex items-center gap-x-2 px-2.5 py-0 outline-none first:pt-1 last:pb-1 hover:!bg-primary/10 active:!bg-primary/10"
                    >
                      <Checkbox
                        id={camelCase(value.title)}
                        name={key}
                        ripple={false}
                        color="primary"
                        checked={selectedColumns.includes(key)}
                        onChange={handleColumnCheckbox}
                        containerProps={{className: 'p-0'}}
                        className="border-primary bg-white before:h-5 before:w-5 checked:border-none checked:bg-primary hover:before:opacity-0"
                        label={value.title}
                        labelProps={{className: 'px-3 py-2 text-black-800 font-semibold text-base'}}
                      />
                    </div>
                  );
                })}
            </MenuList>
          </Menu>
          <Box margin="auto">
            <VisibleContent keyPath="salesOrders.dashboard.createOrderButton">
              <RouterLink to={AUTH_SELLING_SALES_ORDER_CREATE} data-autoid="lnkCreateSalesOrder">
                <Button className="mr-1">Create Order</Button>
              </RouterLink>
            </VisibleContent>
          </Box>
        </div>
      </div>
      <Collapse timeout={250} in={showFilter} className={'w-full'}>
        <div className={clsx(classes.filterContainer, classes.dateRangeStyles)}>
          <SSearchInput
            inputRef={searchInputRef}
            loading={loading}
            searchFilter={searchFilter}
            placeholder={`Search Customer Name, Email , Order ID`}
            handleChange={handleSearchInput}
          />
          <DateRangePicker
            isOutsideRange={day => moment().diff(day) < 0}
            startDatePlaceholderText={'DD/MM/YYYY'}
            endDatePlaceholderText={'DD/MM/YYYY'}
            startDate={startDate}
            startDateId="your_unique_start_date_id"
            endDate={endDate}
            endDateId="your_unique_end_date_id"
            onDatesChange={handlendDatesChange}
            focusedInput={focusedInput}
            onFocusChange={handleFocusChange}
          />
          <div className="flex">
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={toggleFilter}
              data-autoid="btnCloseFilter"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </Collapse>
    </STableToolbar>
  );
};
